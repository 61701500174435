h1 {
  font-size: 3rem;
}
h2 {
  font-size: 2.75rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2.25rem;
}
h5 {
  font-size: 2rem;
}
h6 {
  font-size: 1.5rem;
}
p {
  font-size: 1rem;
  min-height: 1rem;
}
